/* global $, MatgenGlobal, M4CGlobal, bootstrap, dataLayer, g_recaptcha_token, sitekey */

import { v4 as UUID } from 'uuid';
import {
  buildQueryString,
  processStudyForm,
  translateAnswerTags,
} from './data/index.js';
import {
  createStudyForm,
  forgotPasswordModal,
  killModal,
  loadStudySelect,
  loginModal,
  mainContentLoader,
  mainContentLoaderStop,
  resetPasswordModal,
  signupModal,
  studyCreate,
} from './display';

import { saveMaterial } from '../editor-page/editor/events.js';

window.mainContentLoader = mainContentLoader;

const init = () => {
  if (!MatgenGlobal.authEventsInitialized) {
    $(document).on('show.bs.modal', () => {
      //console.error('SHOW MODAL');
      $('#matgen-whole').css(
        'transition',
        'grayscale 2s, brightness 2s, blur 2s'
      );
      $('#matgen-whole').css(
        'filter',
        'grayscale(100%) brightness(1.5) blur(3px)'
      );
    });

    $(document).on('matgen-event-loader-start', (e) => {
      //console.error('LOADER START:', e.detail);
      if (e.detail.p.group && e.detail.p.group.includes('download')) {
        if (e.detail.p.group && e.detail.p.group === 'download-get-material') {
          mainContentLoader({ showMessage: true });
          $('#loader-message').text('Downloading material...');
        }
        if (
          e.detail.p.group &&
          e.detail.p.group === 'download-generating-image'
        ) {
          mainContentLoader({ showMessage: true });
          $('#loader-message').text('Generating image...');
        }
      }
      if (e.detail.p.group && e.detail.p.group === 'matgen-login') {
        //killModal('#matgen-signin-modal');
        mainContentLoader({ showMessage: true });
        $('#loader-message').text('Logging in...');
      }
    });

    $(document).on('download-pdf-generated', () => {
      mainContentLoaderStop();
    });

    $(document).on('hidden.bs.modal', '#image-select-modal', () => {
      mainContentLoaderStop();

      MatgenGlobal.emit({
        event: 'download-pdf-generated-modal-closed',
      });
    });

    $(document).on('download-pdf-generated-modal-closed', () => {
      mainContentLoaderStop();
      if (
        !sessionStorage.getItem('dontShowTestimonialModal') &&
        !localStorage.getItem('dontShowTestimonialModal')
      ) {
        MatgenGlobal.M4CModal.show({
          id: `testimonial-modal`,
          title: 'Have feedback for us?',
          content: `
          <div id="testimonial-modal-content">
            <p>
              We would love to hear any feedback you had on your experience using OutreachPro. If you'd like to provide <strong>feedback</strong> or a <strong>testimonial</strong>, please fill out your information below and someone from our team will get in touch with you shortly. Thank you for using OutreachPro!
            </p>
            <div>
              <form id="testimonial-form" style="text-align: left;">
                <label>Name</label>
                <input type="text" class="form-control" id="name" required>
                <br>
                <label>Email Address</label>
                <input type="email" class="form-control" id="email" required>
              </form>
            </div>
            <br><br>
            <div>
              <button id="not-interested" class="button--hollow button--blue btn btn-secondary">I'm not interested now</button>
              <button id="contact-me" class="button--blue btn btn-primary closeModal">Yes, please contact me</button>
            </div>
            <br>
            <p style="text-align: center;"><label for="dont-show-again"><input type="checkbox" id="dont-show-testimonial-again"> Don't show me this again</label></p>
          </div>          
          `,
        });
      }

      // TODO: Remove below in order to turn on the testimonial modal only showing once
      // sessionStorage.setItem('dontShowTestimonialModal', true);
    });

    $(document).on('click keypress', '#contact-me', async () => {
      const valid = $('#testimonial-form')[0].reportValidity();

      if (valid) {
        // Save feedback in DB
        const body = {
          id: UUID(),
          name: $('#name').val(),
          email: $('#email').val(),
        };

        console.log(body);
        const response = await MatgenGlobal.Data.saveTestimonial(body, false);
        console.log(response);

        $('#testimonial-modal-content').html(`
        <p>
        Thank you! Someone from our team will be in touch with you shortly.
        </p>
      `);

        $('#testimonial-modal-label').html('');

        // TODO:
        // Send a confirmation email to the user (from what email?)
        const formData = new FormData();

        formData.append('from', 'web@outreachpro.one');
        formData.append('to', body.email);
        formData.append(
          'subject',
          'Confirmation for OutreachPro feedback request'
        );
        formData.append(
          'html',
          `
            <p>Thank you for offering to provide feedback or a testimonial on your experience using OutreachPro. We have received your contact information and a team member from NIA will follow up with you in the next few business days.</p>
            <p>Name: ${body.name}</p>
            <p>Email address: ${body.email}</p>

            <p>Sincerely,</p>
            <p>The OutreachPro Team</p>
            `
        );
        //console.log(data);
        //console.log(formData);

        const Emailresponse = await fetch(
          'https://slimqgs1wa.execute-api.us-east-1.amazonaws.com/v1/mailer',
          {
            method: 'POST',
            mode: 'cors',
            body: formData,
          }
        );
        console.log(Emailresponse);

        const formData2 = new FormData();
        const date = new Date();
        console.log(date.toLocaleString());

        formData2.append('from', 'web@outreachpro.one');
        formData2.append('to', 'productdevelopment@forchange.agency');
        formData2.append(
          'subject',
          'A new NIA user would like to provide feedback.'
        );
        formData2.append(
          'html',
          `
            <p>A new user has submitted a feedback request on OutreachPro. Here are the details:</p>
            <p>Name: ${body.name}</p>
            <p>Email address: ${body.email}</p>
            <p>Date Created: ${date.toLocaleString()}</p>
            `
        );

        const Emailresponse2 = await fetch(
          'https://slimqgs1wa.execute-api.us-east-1.amazonaws.com/v1/mailer',
          {
            method: 'POST',
            mode: 'cors',
            body: formData2,
          }
        );
        console.log(Emailresponse2);

        // Send an email to productdevelopment@forchange.agency letting us know someone submitted the form
        // Built out a report that displays the date of submission, name, and email address of all form submissions
      }
    });

    $(document).on('click keypress', '#not-interested', () => {
      mainContentLoaderStop();
      $('#matgen-whole').css('transition', 'initial');
      $('#matgen-whole').css('filter', 'initial');
      killModal('#testimonial-modal');
    });

    $(document).on('click keypress', '#dont-show-testimonial-again', () => {
      if ($('#dont-show-testimonial-again').is(':checked')) {
        localStorage.setItem('dontShowTestimonialModal', true);
      } else {
        localStorage.removeItem('dontShowTestimonialModal');
      }
    });

    $(document).on('matgen-event-loader-stop', (e) => {
      //console.error('LOADER STOP:', e.detail);
      /*if (
        e.detail.startArray.find((a) => a.group === 'download-pdf-generated') ||
        e.detail.startArray.find(
          (a) => a.group === 'download-generating-image'
        ) ||
        e.detail.startArray.find((a) => a.group === 'download-download')
      ) {
        mainContentLoaderStop();
      }*/
      if (e.detail.startArray.find((a) => a.group === 'matgen-login')) {
        $('#matgen-whole').css('filter', 'initial');
        mainContentLoaderStop();
      }
    });

    /*$(document).on('matgen-event-loader-start', (e) => {
      console.error('AUTH GLOBAL LOADER START:', e.detail);
    });*/

    /*$(document).on('matgen-event-loader-stop', (e) => {
      console.error('LOADER STOP:', e);
    });*/

    /*$(document).on('matgen-event-loader-tick', (e) => {
      console.error('LOADER TICK:', e.detail);
    });*/

    /*$(document).on('matgen-event-loader-error', (e) => {
      console.error('LOADER ERROR:', e.detail);
    });*/
    $(document).on('click keypress', '.use-example', (e) => {
      e.preventDefault();
      $('#preview-tab').addClass('active');
      $('#examples-tab').removeClass('active');
      $('#preview-tab-pane').addClass('show');
      $('#preview-tab-pane').addClass('active');
      $('#examples-tab-pane').removeClass('show');
      $('#examples-tab-pane').removeClass('active');

      const content = $(e.target).parent().siblings('.example-content').html();
      const question_id = $('#rich-text-update').attr('data-question-id');
      M4CGlobal.quill[question_id].root.innerHTML = content;

      MatgenGlobal.changed = true;
    });

    $(document).on('hidden.bs.modal', () => {
      if ($('.modal.show').length === 0) {
        $('#matgen-whole').css('transition', 'initial');
        $('#matgen-whole').css('filter', 'initial');
      }
      if (!MatgenGlobal.suppressLoaderStop) {
        mainContentLoaderStop();
      }
    });

    $(document).on('hide.bs.modal', '#video-modal', () => {
      const div = document.getElementById('video-modal');
      const iframe = div.getElementsByTagName('iframe')[0].contentWindow;
      iframe.postMessage('{"method":"pause"}', '*');
    });

    $(document).on('click keypress', '#video-close-btn', (e) => {
      if (MatgenGlobal.UI.a11yClick(e) === true) {
        const modal = bootstrap.Modal.getOrCreateInstance('#video-modal');
        modal.hide();
      }
    });

    $(document).on('click keypress', '#reset-password-link', (e) => {
      if (MatgenGlobal.UI.a11yClick(e) === true) {
        e.preventDefault();
        killModal('#matgen-signin-modal');
        killModal('#forgot-password-modal');
        resetPasswordModal();
      }
    });

    $(document).on('click keypress', '#forgot-password-link', (e) => {
      if (MatgenGlobal.UI.a11yClick(e) === true) {
        e.preventDefault();
        killModal('#matgen-signin-modal');
        forgotPasswordModal();
      }
    });

    $(document).on('click keypress', '.login-modal-link', (e) => {
      if (MatgenGlobal.UI.a11yClick(e) === true) {
        e.preventDefault();
        killModal('#require-auth-modal');
        loginModal();
      }
    });

    $(document).on('click keypress', '.signup-modal-link', (e) => {
      if (MatgenGlobal.UI.a11yClick(e) === true) {
        e.preventDefault();
        killModal('#require-auth-modal');
        killModal('#matgen-signin-modal');
        signupModal();
      }
    });

    const tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    [...tooltipTriggerList].map(
      (tooltipTriggerEl) =>
        new bootstrap.Tooltip(tooltipTriggerEl, {
          delay: {
            show: 1200,
            hide: 0,
          },
        })
    );

    $(document).on('click keypress', '#study-specific-swimlane-new', (e) => {
      if (MatgenGlobal.UI.a11yClick(e) === true) {
        e.preventDefault();
        studyCreate();
        $('#study-select-content').show();
        createStudyForm('#study-select-content');
      }
    });

    $(document).on('click keypress', '#study-specific-swimlane', (e) => {
      if (MatgenGlobal.UI.a11yClick(e) === true) {
        e.preventDefault();
        window.location.href = `/${
          MatgenGlobal.QuestionnairePage
        }${buildQueryString({
          tags: [
            {
              question_id: 'ba6ad823-e74d-42da-a378-af396d5faca8',
              answer_id: 'b03c4dfa-e4cb-49a5-8f8f-9a17b61be966',
              type: 'bool',
            },
          ],
          page: false,
          opener: window.location.href.includes('create/materials.html')
            ? 'create'
            : 'home',
        })}`;
      }
    });

    $(document).on('click keypress', '#clinical-studies-swimlane', (e) => {
      if (MatgenGlobal.UI.a11yClick(e) === true) {
        e.preventDefault();
        window.location.href = `/${
          MatgenGlobal.QuestionnairePage
        }${buildQueryString({
          tags: [
            {
              question_id: 'ba6ad823-e74d-42da-a378-af396d5faca8',
              answer_id: '2d425dfd-8467-41af-9c74-a20cea8145cb',
              type: 'bool',
            },
          ],
          page: false,
          opener: window.location.href.includes('create/materials.html')
            ? 'create'
            : window.location.href.includes('my-materials.html')
            ? 'my_materials'
            : 'home',
        })}`;
      }
    });

    $(document).on('click keypress', '#brain-health-swimlane', (e) => {
      if (MatgenGlobal.UI.a11yClick(e) === true) {
        e.preventDefault();
        window.location.href = `/${
          MatgenGlobal.QuestionnairePage
        }${buildQueryString({
          tags: [
            {
              question_id: 'ba6ad823-e74d-42da-a378-af396d5faca8',
              answer_id: '78f2fda5-b8a8-4230-a4c8-05e9dfa1efae',
              type: 'bool',
            },
          ],
          page: false,
          opener: window.location.href.includes('create/materials.html')
            ? 'create'
            : window.location.href.includes('my-materials.html')
            ? 'my_materials'
            : 'home',
        })}`;
      }
    });

    $(document).on('mouseenter', '.material-box', function () {
      $('.selected').removeClass('selected');
      $('.active').removeClass('active');

      $(this).addClass('selected');
      $($(this).data('window')).addClass('active');
    });

    $(document).on('focus', '.material-box', function () {
      $('.selected').removeClass('selected');
      $('.active').removeClass('active');

      $(this).addClass('selected');
      $($(this).data('window')).addClass('active');
    });

    $('.material-box').keydown(function (e) {
      const keyCode = e.keyCode || e.which;
      const $currentTab = $(this);

      switch (keyCode) {
        case 37: // Left arrow key
          e.preventDefault();
          $currentTab.attr('tabindex', '-1');
          $currentTab.prev().attr('tabindex', '0').focus();
          $(`#${$currentTab.attr('aria-controls')}`).attr('tabindex', '0');
          $(`#${$currentTab.prev().attr('aria-controls')}`).attr(
            'tabindex',
            '0'
          );
          break;
        case 39: // Right arrow key
          e.preventDefault();
          $currentTab.attr('tabindex', '-1');
          $currentTab.next().attr('tabindex', '0').focus();
          $(`#${$currentTab.attr('aria-controls')}`).attr('tabindex', '0');
          $(`#${$currentTab.next().attr('aria-controls')}`).attr(
            'tabindex',
            '0'
          );
          break;
      }
    });

    $(document).on('click keypress', '#user-signout', (e) => {
      if (MatgenGlobal.UI.a11yClick(e) === true) {
        e.preventDefault();
        MatgenGlobal.AuthUser.signOut(false, () => {
          window.setTimeout(() => {
            window.location.href = '/';
          }, 125);
        });
      }
    });

    $(document).on('matgen-event-loader-start', (e) => {
      //console.error('GLOBAL LOADER START:', e.detail.p.group, e.detail);
      if (e.detail.p.group === 'user-create') {
        if (!MatgenGlobal.initialUserLoadCompleted) {
          mainContentLoader({ showMessage: true });
          $('#loader-message').text('Checking user authentication status...');
          MatgenGlobal.initialUserLoadCompleted = true;
        }
      }
    });

    $(document).on('matgen-material-preview', () => {
      mainContentLoaderStop();
    });

    $(document).on('matgen-event-loader-stop', (e) => {
      /*console.error(
        'GLOBAL LOADER STOP:',
        e.detail,
        !MatgenGlobal.suppressMainLoaderStop &&
          e.detail.startArray.length === 2 &&
          !!e.detail.startArray.find((a) => a.group === 'user-create') &&
          !!e.detail.startArray.find((a) => a.group === 'get-user')
      );*/

      if (
        !MatgenGlobal.suppressMainLoaderStop &&
        e.detail.startArray.length === 2 &&
        !!e.detail.startArray.find((a) => a.group === 'user-create') &&
        !!e.detail.startArray.find((a) => a.group === 'get-user')
      ) {
        MatgenGlobal.emit({ event: 'matgen-event-user-created' });
      }
    });

    $(document).on('matgen-event-user-created', () => {
      mainContentLoaderStop();
    });

    $(document).on('click keypress', 'input[name=study-select]', async (e) => {
      if (MatgenGlobal.UI.a11yClick(e) === true) {
        if ($('input[name=study-select]:checked').val() === 'existing') {
          await loadStudySelect();
        }
        if ($('input[name=study-select]:checked').val() === 'new') {
          $('#study-select-content').empty().show();
          createStudyForm('#study-select-content');
        }
      }
    });

    $(document).on('click keypress', '#study-select-cancel', (e) => {
      if (MatgenGlobal.UI.a11yClick(e) === true) {
        killModal('#study-select-modal');
        mainContentLoaderStop();
      }
    });

    $(document).on('click keypress', '#study-select-continue', async (e) => {
      if (MatgenGlobal.UI.a11yClick(e) === true) {
        if (
          window.location.href.includes('template-picker.html') ||
          window.location.href.includes('editor.html')
        ) {
          if ($('#existing-studies-select-element').length > 0) {
            $(e.target).attr(
              'data-study-id',
              $('#existing-studies-select-element').val()
            );

            killModal('#study-select-modal');
            saveMaterial({ e });
          } else if ($('#study-name').length > 0) {
            const study_id = await processStudyForm();
            $(e.target).attr('data-study-id', study_id);
            saveMaterial({ e });
          } else {
            if ($('input[name=study-select]:checked').val() === 'existing') {
              await loadStudySelect();
            }
            if ($('input[name=study-select]:checked').val() === 'new') {
              $('#study-select-content').empty().show();
              createStudyForm('#study-select-content');
            }
          }
        } else {
          console.log('website');
          if ($('#existing-studies-select-element').length > 0) {
            const study_id = $('#existing-studies-select-element').val();
            let queryString = {
              tags: MatgenGlobal.answerTags,
              study_id: study_id,
            };
            const grantNumber = $(e.target).attr('data-grant-number');

            if (grantNumber != '') {
              queryString = {
                tags: MatgenGlobal.answerTags,
                study_id: study_id,
                grant_number: grantNumber,
              };
            }

            try {
              const id = UUID();
              console.log('uuid::', id);

              const response = await MatgenGlobal.Data.saveMicrosite(
                id,
                null, //subdomain
                MatgenGlobal.AuthUser.user.username,
                study_id,
                null, //template_id
                null, //html
                null, //json
                false, //edit
                new Date().toISOString()
              );

              console.log(response);

              const db_response = await MatgenGlobal.Data.API.request(
                `/irb_data`,
                'POST',
                {
                  microsite_id: id,
                  name: '',
                  email: '',
                  phone: '',
                  affiliation: '',
                  nia_study_id: grantNumber,
                  updated: new Date(),
                  academic_grantee_institution: '',
                  edit: false,
                }
              );
              console.log(db_response);

              const url = `/${
                MatgenGlobal.WebsiteDataPage
              }${MatgenGlobal.buildQueryString(queryString)}`;
              window.location.href = url;
            } catch (e) {
              console.error(e);
              MatgenGlobal.UI.stopLoading();
              return false;
            }
          } else if ($('#study-name').length > 0) {
            const study_id = await processStudyForm();

            let queryString = {
              tags: MatgenGlobal.answerTags,
              study_id,
            };

            const grantNumber = $(e.target).attr('data-grant-number');

            if (grantNumber != '') {
              queryString = {
                tags: MatgenGlobal.answerTags,
                study_id,
                grant_number: grantNumber,
              };
            }

            try {
              const id = UUID();
              console.log('uuid::', id);

              const response = await MatgenGlobal.Data.saveMicrosite(
                id,
                null, //subdomain
                MatgenGlobal.AuthUser.user.username,
                study_id,
                null, //template_id
                null, //html
                null, //json
                false, //edit
                new Date().toISOString()
              );

              console.log(response);

              const db_response = await MatgenGlobal.Data.API.request(
                `/irb_data`,
                'POST',
                {
                  microsite_id: id,
                  name: '',
                  email: '',
                  phone: '',
                  affiliation: '',
                  nia_study_id: grantNumber,
                  updated: new Date(),
                  academic_grantee_institution: '',
                  edit: false,
                }
              );
              console.log(db_response);

              const url = `/${
                MatgenGlobal.WebsiteDataPage
              }${MatgenGlobal.buildQueryString(queryString)}`;
              window.location.href = url;
            } catch (e) {
              console.error(e);
              MatgenGlobal.UI.stopLoading();
              return false;
            }
          } else {
            if ($('input[name=study-select]:checked').val() === 'existing') {
              await loadStudySelect();
            }
            if ($('input[name=study-select]:checked').val() === 'new') {
              $('#study-select-content').empty().show();
              createStudyForm('#study-select-content');
            }
          }
        }
      }
    });

    $(document).on('click', '.ql-container.ql-snow', (e) => {
      if (MatgenGlobal.UI.a11yClick(e) === true) {
        $(e.target).closest('.ql-editor.ql-blank').focus();
      }
    });

    $(document).on('click keypress', '#template-manage', (e) => {
      if (MatgenGlobal.UI.a11yClick(e) === true) {
        e.preventDefault();
        location = '/admin/templates.html';
      }
    });

    $(document).on('click keypress', '#microsite-manage', (e) => {
      if (MatgenGlobal.UI.a11yClick(e) === true) {
        e.preventDefault();
        location = '/admin/websites.html';
      }
    });

    $(document).on('click keypress', '#study-create-cancel', (e) => {
      if (MatgenGlobal.UI.a11yClick(e) === true) {
        killModal('#study-create-modal');
      }
    });

    $(document).on('click keypress', '#study-create-continue', async (e) => {
      if (MatgenGlobal.UI.a11yClick(e) === true) {
        if ($('#study-create-form')[0].checkValidity()) {
          mainContentLoader({ showMessage: true });
          $('#loader-message').text('Creating study...');
          try {
            await processStudyForm();
            window.location.reload();
          } catch (e) {
            console.error(e);
            mainContentLoaderStop();
            MatgenGlobal.UI.handleError(
              'Error',
              'There was an creating your study. Please try again later or contact support.'
            );
          }
          mainContentLoaderStop();
        } else {
          $('#study-create-form')[0].reportValidity();
        }
      }
    });

    $(document).on('matgen-material-download', async (e) => {
      let mtags;
      if (e.detail && e.detail.material && e.detail.material.tags) {
        try {
          mtags = JSON.parse(e.detail.material.tags);
        } catch (e) {
          console.error('Bad tag JSON');
        }
        const tags = await translateAnswerTags(mtags);

        const dist = tags.find((t) => t.type === 'distribution');
        //const distributionQuestion = dist.q;
        const distributionAnswer = dist.a;

        const goal = tags.find((t) => t.type === 'goal');
        //const goalQuestion = goal.q;
        const goalAnswer = goal.a;

        const demo = tags.filter((t) => t.type === 'demographics');

        dataLayer.push({
          param1: goalAnswer,
          param2: distributionAnswer,
          param3: demo[0].a,
          param4: demo[1].a,
          param5: e.detail.template.name,
          event: 'matgen-material-download',
        });
      } else {
        dataLayer.push({
          param5: e.detail.template.name,
          event: 'matgen-material-download',
        });
      }
    });

    $(document).on('submit', '#feedback-contact-form', async (e) => {
      e.preventDefault();

      if (!g_recaptcha_token) {
        return false;
      }
      mainContentLoader({ showMessage: true });
      $('#loader-message').text('Submitting contact request...');
      try {
        const response = await MatgenGlobal.MatgenPageLoader.start({
          message: 'Submitting form',
          promise: fetch(
            'https://slimqgs1wa.execute-api.us-east-1.amazonaws.com/v1/recaptcha',
            {
              method: 'POST',
              mode: 'cors',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                token: g_recaptcha_token,
                siteKey: sitekey,
                action: 'EMAIL_SUBMIT',
              }),
            }
          ),
        });
        const recaptcha = await response.json();
        if (
          !recaptcha.riskAnalysis ||
          !recaptcha.riskAnalysis.score ||
          recaptcha.riskAnalysis.score < 0.7
        ) {
          mainContentLoaderStop();

          return false;
        }
        //console.log(recaptcha);
      } catch (e) {
        console.error(e);
        mainContentLoaderStop();
        return false;
      }

      MatgenGlobal.UI.validateForm('feedback-contact-form', async () => {
        const data = {};
        $('#feedback-contact-form')
          .serializeArray()
          .forEach((a) => {
            data[a.name] = a.value;
          });
        try {
          const formData = new FormData();

          formData.append('from', 'web@outreachpro.one');
          formData.append('to', 'helpdesk@forchange.agency');
          //formData.append('to', 'web@forchange.agency');
          formData.append('subject', 'OutreachPro Help Desk Ticket');
          formData.append(
            'text',
            `
            Helpdesk form submission:
              Name: ${data.name}
              Email: ${data.email}
              Question1: ${data.question1}
              Question2: ${data.question2}
            `
          );
          formData.append('attachment', $('#feedback-file')[0].files[0]);
          //console.log(data);
          //console.log(formData);

          const response = await fetch(
            'https://slimqgs1wa.execute-api.us-east-1.amazonaws.com/v1/mailer',
            {
              method: 'POST',
              mode: 'cors',
              body: formData,
            }
          );

          console.log(response);
          mainContentLoaderStop();

          if (response.status !== 200) {
            console.error(e);
            $('#feedback-contact-form').replaceWith(
              $(`
                    <div class="alert alert-danger" role="alert">
                      <h3 class="alert-heading">Error</h3>
                      <hr>
                      <p>There was a problem sending your message. Please try again later.</p>
                      <hr>
                    </div>
                  `)
            );
          } else {
            /*dataLayer.push({
              event: 'matgen-contact',
            });*/
            $('#feedback-contact-form').replaceWith(
              $(`
                <div class="alert alert-success" role="alert">
                  <h3 class="alert-heading">Message Sent</h3>
                  <hr>
                  <p>Your Help Desk request has been sent. Our Help Desk team will respond within 1-2 business days.</p>
                  <hr>
                </div>
              `)
            );
          }
        } catch (e) {
          console.error(e);
          $('#feedback-contact-form').replaceWith(
            $(`
                <div class="alert alert-danger" role="alert">
                  <h3 class="alert-heading">Error</h3>
                  <hr>
                  <p>There was a problem sending your message. Please try again later.</p>
                  <hr>
                </div>
              `)
          );
        }
        MatgenGlobal.UI.stopLoading();
      });
    });

    $(document).on('click keypress', '.thumb-page', async (e) => {
      if (MatgenGlobal.UI.a11yClick(e) === true) {
        e.preventDefault();
        $('#page-thumb')
          .css('visibility', 'hidden')
          .removeClass('missing-thumb');
        mainContentLoader({
          showMessage: true,
          target: 'body',
          addClass: 'opaque-overlay',
        });
        $('#loader-message').text('Loading page...');
        const id = $(e.target).attr('data-id');
        const page_id = $(e.target).attr('data-page-id');
        const url = await MatgenGlobal.Data.getMaterialPreviewURL(id, page_id);

        mainContentLoaderStop();
        $('#page-thumb').css('visibility', 'visible');
        $('#page-thumb').attr('src', url);
      }
    });

    $(document).on('matgen-create-account', async () => {
      const db_response = await MatgenGlobal.Amplify.API.post(
        'public',
        '/signup-surveys',
        {
          body: {
            user_name: $('#m4c-signup-email').val(),
            created_date: new Date(),
            survey_sent: 0,
          },
        }
      );
      console.log(db_response);
    });

    MatgenGlobal.authEventsInitialized = true;
  }

  //homepage swimlane vars
  $(document).on('click keypress', '.material-box', (e) => {
    const materialClicked = $(e.currentTarget).attr('id');
    sessionStorage.setItem('materialClicked', materialClicked);
  });

  //material create page swimlane vars
  $(document).on('click keypress', '.info-box', (e) => {
    const materialClicked = $(e.currentTarget).attr('id');
    sessionStorage.setItem('materialClicked', materialClicked);
  });
};

export default init;
